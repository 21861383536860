import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

class MessageFormData extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;
    return (
      <MuiThemeProvider>
       
       <div className="fixed_form_wrapper">
        <div className="fixed_form">
              <h3 className="question"> Create a Post to the Message Board</h3>
              <TextField
                hintText="Your Name"
                floatingLabelText="Please write in your Name"
                onChange={handleChange("userName")}
                value={values.userName}
                id="userName"
                name="userName"
              />
              <br />
              <TextField
                hintText="Message Title"
                floatingLabelText="Please write in your Message Title"
                onChange={handleChange("title")}
                value={values.title}
                id="title"
                name="title"
              />
              <br />
              <TextareaAutosize aria-label="minimum height" rowsMin={3}  placeholder="Message Description"
                onChange={handleChange("description")}
                value={values.description}
                id="description"
                name="description"
                className="text_area"
              />
              <br />
              <RaisedButton
                label="Back"
                primary={false}
                style={styles.button}
                onClick={this.back}
              />
              <RaisedButton
                label="Continue"
                primary={true}
                style={styles.button}
                onClick={this.continue}
              />

              
            </div>

          </div>
  <div className="pad_bottom"></div>     
      </MuiThemeProvider>
    );
  }
}

const styles = {
  button: {
    marginTop: 15,
    marginRight:15,
  }
};


export default MessageFormData;

import React from "react";
import firebase from "../components/firebase";
import Helmet from 'react-helmet'
import "../components/Layout/layout.css";
import MessageBoardForm from '../components/MessageBoardForm'
import Avatar from '@material-ui/core/Avatar';
import NavBar from "../components/navbar";

const db = firebase.firestore();

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
  }

  componentDidMount() {
    db.collection("messages")
    .where("approved", "==", true)
      .get()
      
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        this.setState({ messages: data });
      });
  }

  render() {
    const { messages } = this.state;
    return (
      <>

        <Helmet>
          <body class='message_page' />
        </Helmet>
        <NavBar></NavBar>
          <MessageBoardForm/>


              {messages.map((message) => (
               
                <div key={message.id} className="messsage_container">
                  <div className="avatarContainer">
                    <div className="avatar">
                  <Avatar className="avatar_BG">{message.message_userName[0]}</Avatar></div>
                  <div className="subAvatar">
                  {message.message_userName} 
                  <span> {message.date}</span>
                  </div>
                  </div>
                  <div className="messsage_copy">
                  <h3>{message.message_title}</h3>
                  <p>{message.message_description}</p>
                  </div>
                  <img className="img_responsive override_img" src={ `${message.message_url}`}></img>

                 

                </div>
              ))}

      </>

    );
  }
}

export default Messages;
import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import RaisedButton from "material-ui/RaisedButton";

class MessageFormImageChoice extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  skip = e => {
    e.preventDefault();
    localStorage.setItem('G4C_IMAGE_PATH_MESSAGE', "");
    this.props.jumpStep();
  };

  render() {
    const { values, handleChange } = this.props;
    return (
      <MuiThemeProvider>

        <div className="fixed_form_wrapper">
          <div className="fixed_form">
            <h3 className="question"> Add an image?</h3>
            
            <button
              onClick={this.skip}
              className="no"
            >
              No
        </button>
        <button
              onClick={this.continue}
              className="yes"

            >
              Yes
        </button>
            <br />
            <RaisedButton
              label="Back"
              primary={false}
              style={styles.button}
              onClick={this.back}
            />



          </div>

        </div>
        <div className="pad_bottom"></div>
      </MuiThemeProvider>
    );
  }
}

const styles = {
  button: {
    marginTop: 15,
    
  }
};


export default MessageFormImageChoice;

import React, { Component } from "react";
import MessageFormStart from "./MessageFormStart";
import MessageFormData from "./MessageFormData";
import MessageFormImageChoice from "./MessageFormImageChoice";
import MessageFormImage from "./MessageFormImage";
import FinalMessage from "./FinalMessage";



import firebase from "../components/firebase"

const db = firebase.firestore();
var today = new Date();
var date = (today.getMonth() + 1) + '-' + ('0' + today.getDate()).slice(-2) + '-' + today.getFullYear()

class MessageBoardForm extends Component {
  state = {
    step: 1,
    userName: "",
    description: "",
    title: "",
    url: "",
    date: "",
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };
  jumpStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 2
    });
  };
  // go back to the previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  handleSubmit = e => {
    if (typeof window !== "undefined") {
      var g4cImagePath = localStorage.getItem('G4C_IMAGE_PATH_MESSAGE');
    }
    e.preventDefault()
    db.collection('messages').add({
      message_userName: this.state.userName,
      message_description: this.state.description,
      message_title: this.state.title,
      message_url: g4cImagePath,
      date: date,
      approved:false,


    });

    this.setState({
      step: 1,
      userName: "",
      description: "",
      title: "",
      url: "",
      date: "",
    });
  }

  //Handle fields change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };
  render() {
    const { step } = this.state;
    const { userName, description, title, url } = this.state;
    const values = { userName, description, title, url };

    switch (step) {
      case 1:
        return (

          <MessageFormStart
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 2:
        return (

          <MessageFormData
            nextStep={this.nextStep}
            prevStep={this.prevStep}

            handleChange={this.handleChange}
            values={values}
          />
        );
      case 3:
        return (
          <MessageFormImageChoice
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            jumpStep={this.jumpStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 4:
        return (
          <MessageFormImage
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 5:
        return (
          <FinalMessage
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            handleSubmit={this.handleSubmit}
          />
        );
      default:
        break;
    }


  }
}

export default MessageBoardForm;

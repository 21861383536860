import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import RaisedButton from "material-ui/RaisedButton";


class FinalMessage extends Component {
  continue = e => {
    this.props.handleSubmit(e);
    
  };
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      values: { userName, description, title  }
    } = this.props;

    return (
      <MuiThemeProvider>
      <React.Fragment>
      <div className="fixed_form_wrapper">
      <div className="fixed_form">

        <p className="submit_message" >Click Below to Submit to the Message Board:</p>
        <small className="disclaimer" ><i>Once submitted, this will be reviewed and posted once approved.</i></small>

        <input className="upload_Button show" type="submit" value="Submit" onClick={this.continue}></input>
        <br />
        <RaisedButton
              label="Back"
              primary={false}
              style={styles.button}
              onClick={this.back}
            />
    </div></div>
<div className="pad_bottom"></div>      </React.Fragment>
    </MuiThemeProvider>
    );
  }
}

const styles = {
  button: {
    margin: 15
  }
};

export default FinalMessage;
